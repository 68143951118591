.btnVisitaSitio {
  background-color: var(--violet-dark-web-space);
  cursor: pointer;
  border: none;
  border-radius: 2rem;
  align-items: center;
  width: 19.1rem;
  height: 3.2rem;
  margin-top: 3.2rem;
  margin-bottom: 6.4rem;
  text-decoration: none;
  transition: all .1s ease-in;
  display: flex;
  box-shadow: .4rem .4rem 2rem #0003;
}

.btnVisitaSitio img {
  margin-left: 1.6rem;
}

.btnVisitaSitio p {
  color: #fff;
  margin-left: .5rem;
  font-family: Akzidenz-Grotesk Pro Ext, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.792rem;
}

.btnVisitaSitio:active {
  transform: scale(1.05);
}
/*# sourceMappingURL=index.781b3992.css.map */
